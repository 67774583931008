<script>
import PageHeader from "@/components/page-header";
import { BredcrumpManagerEncode } from "@/common/bredcrumb-common"
import { networkRequesErrorResponseLogic } from "@/common/http-common-response-error"
import { ApiClientSelectedPlatform } from "@/common/http-common"
export default {
    components: {
        PageHeader
    },
    data() {
        return {
            title: this.$t('country.limit'),
            items: []
        };
    },
    methods: {
        getCities() {
            const self = this;
            document.getElementById("preloader").style.display = "block";
            document.getElementById("status").style.display = "block";
            var rs = JSON.parse(atob(this.$route.query.data))
            ApiClientSelectedPlatform(rs.platformId).get("/v1/admin/countries/cities?countryId=" + rs.itemId).then(function (response) {
                self.items = response.data.items
                document.getElementById("preloader").style.display = "none";
                document.getElementById("status").style.display = "none";
                BredcrumpManagerEncode(self, { path: self.$route.path, title: 'country.limit' })
            }).catch(
                function (error) {
                    networkRequesErrorResponseLogic(self, error)
                }
            )
        },
        submitForm() {
            const self = this;
            document.getElementById("preloader").style.display = "block";
            document.getElementById("status").style.display = "block";
            var rs = JSON.parse(atob(this.$route.query.data))
            const json = JSON.stringify(self.items);

            ApiClientSelectedPlatform(rs.platformId).put("/v1/admin/countries/cities?countryId="+rs.itemId, json).then(function () {
                if (self.$route.query.data) {
                    var rs = JSON.parse(atob(self.$route.query.data))
                    if (rs.items) {
                        self.$router.replace({ path: rs.items[rs.items.length - 2].path, query: { data: rs.items[rs.items.length - 2].data } }).catch(() => { });
                    }
                }
            }).catch(
                function (error) {
                    networkRequesErrorResponseLogic(self, error)
                }
            )
        }
    },
    mounted() {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
        this.getCities()
    }
};
</script>
<template>
    <div class="row">
        <div class="col-xl-12" style="padding: 0!important;">
            <PageHeader :title="title" />
        </div>
        <form class="col-xl-12" style="padding: 0!important;" @submit.prevent="submitForm">
            <div class="col-xl-12">
                <div class="row">
                    <div class="col-xl-12" v-for="(item, index) in items" :key="index">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">{{ $t('form.country.fields.cityField') }} {{ item.title }}</h4>
                                <p class="card-title-desc">
                                </p>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="container" style="margin: 0!important;">
                                            <div class="row">

                                                <div class="col-md-4" v-for="(item, index) in item.municipalities"
                                                    :key="index">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="row">
                                                                <div class="custom-control custom-checkbox mb-3 col-12">
                                                                    <input type="checkbox" class="custom-control-input"
                                                                        v-model="item.selected" :id="item.id" />
                                                                    <label class="custom-control-label"
                                                                        style="font-size:0.8rem!important" :for="item.id">{{
                                                                            $t('form.country.fields.municipalityField') }} {{
        item.title }}</label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-body">
                        <b-button variant="primary" type="submit">{{
                            $t('buttons.save') }}</b-button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>